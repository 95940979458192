import styled from "styled-components";

export const ModalNoticeContainer = styled.div``;
export const ModalNoticeWrapper = styled.div``;
export const PopupContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  padding-top: 20px;
  .text-info {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
`;
