import { AppContainer } from "./styled";
import Home from "./page/Home";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { useEffect, useMemo } from "react";
import Header from "./components/Header";
import LayoutContainer from "./Layout";
import BuyPackage from "./page/Buy";
import Staking from "./page/Staking";
import Affiliate from "./page/Affiliate";
import Temp from "./page/Temp";
import { Buffer } from "buffer";
import process from "process";
import {
  Adapter,
  BaseSignerWalletAdapter,
  BaseWalletAdapter,
  UnifiedWalletProvider,
  WalletAdapterNetwork,
} from "@jup-ag/wallet-adapter";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TrustWalletAdapter,
  WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import BuyWic from "./page/BuyWic";
import ModalNotice from "./components/Common/ModalNotice";

global.Buffer = Buffer;
global.process = process;

type WalletAdapterWithMutableSupportedTransactionVersions<T> = Omit<
  T,
  "supportedTransactionVersions"
> & {
  supportedTransactionVersions: BaseWalletAdapter["supportedTransactionVersions"];
};

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const metadata = {
    name: "WIC Chain - Unlock the Full Potential of Blockchain",
    description:
      "WIC Chain is a cutting-edge Layer 1 (L1) blockchain platform built to power the future of decentralized applications (dApps) and Web3 ecosystems.",
    url: "https://jup.ag",
    iconUrls: ["https://jup.ag/favicon.ico"],
    additionalInfo: "",
    walletConnectProjectId: "4a4e231c4004ef7b77076a87094fba61",
  };

  const wallets: Adapter[] = useMemo(() => {
    const walletConnectWalletAdapter: WalletAdapterWithMutableSupportedTransactionVersions<BaseSignerWalletAdapter> | null =
      (() => {
        const adapter: any = new WalletConnectWalletAdapter({
          network: WalletAdapterNetwork.Mainnet,
          options: {
            relayUrl: "wss://relay.walletconnect.com",
            projectId: metadata.walletConnectProjectId,
            metadata: {
              name: metadata.name,
              description: metadata.description,
              url: metadata.url,
              icons: metadata.iconUrls,
            },
          },
        });
        adapter.supportedTransactionVersions = new Set(["legacy"]);
        return adapter;
      })();
    return [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new TrustWalletAdapter(),
      walletConnectWalletAdapter,
    ].filter((item) => item && item.name && item.icon) as Adapter[];
  }, []);

  return (
    <Provider store={store}>
      <ContextProvider>
        <UnifiedWalletProvider
          wallets={wallets}
          config={{
            autoConnect: true,
            env: "mainnet-beta",
            metadata: {
              name: "WIC Chain - Unlock the Full Potential of Blockchain",
              description:
                "WIC Chain is a cutting-edge Layer 1 (L1) blockchain platform built to power the future of decentralized applications (dApps) and Web3 ecosystems.",
              url: "https://jup.ag",
              iconUrls: ["https://jup.ag/favicon.ico"],
            },
            walletlistExplanation: {
              href: "https://station.jup.ag/docs/additional-topics/wallet-list",
            },
            theme: "dark",
            lang: "en",
          }}
        >
          <Toaster position="top-right" reverseOrder={false} />
          <AppContainer>
            <ModalNotice />
            <Header />
            <Routes>
              <Route element={<LayoutContainer />}>
                <Route path="/" element={<Home />} />
                <Route path="/buy" element={<BuyPackage />} />
                <Route path="/temp" element={<Temp />} />
                <Route path="/buy-now" element={<BuyWic />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/affiliate" element={<Affiliate />} />
                <Route path="/ref/:ref" element={<Affiliate />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
          </AppContainer>
        </UnifiedWalletProvider>
      </ContextProvider>
    </Provider>
  );
};

export default App;
