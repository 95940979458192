import React, { useEffect, useState } from "react";
import {
  ModalNoticeContainer,
  ModalNoticeWrapper,
  PopupContent,
} from "./styled";
import { Modal } from "antd";
import { useWallet } from "@solana/wallet-adapter-react";

const ModalNotice = () => {
  const [showPopup, setShowPopup] = useState(false);
  const walletSol = useWallet();

  useEffect(() => {
    if (walletSol.publicKey) {
      setShowPopup(true);
    }
  }, [walletSol.publicKey]);

  const handleCloseModal = () => {
    setShowPopup(false);
  };

  return (
    <ModalNoticeContainer>
      <ModalNoticeWrapper>
        <Modal
          title="Announcement"
          open={showPopup}
          onCancel={handleCloseModal}
          footer={false}
          centered
        >
          <PopupContent>
            <p className="text-info">
              Please contact your leader about the new benefits change. Thank
              you
            </p>
          </PopupContent>
        </Modal>
      </ModalNoticeWrapper>
    </ModalNoticeContainer>
  );
};

export default ModalNotice;
